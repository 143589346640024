import {GET, POST} from "@/services/gateway";

const listSnapshots = ({date, dateTo}) => {
  return POST('salesforce/snapshot/list', {date, dateTo})
}

const createBackup = () => {
  return GET('salesforce/backup')
}

const exportSnapshot = ({
  snapshotDateTime,
  sourceIds,
  searchTerm,
  snapshotId,
  selectedIds,
  extension,
  code,
  storageDestinationId,
  dataType = '8',
  storageDestinationType = 0
}) => {
  return POST('salesforce/export', {
    snapshotDateTime,
    sourceIds,
    searchTerm,
    snapshotId,
    selectedIds,
    extension,
    storageDestinationId,
    dataType,
    storageDestinationType
  }, {code: code ? code : null})
}

const connectSetup = (data) => {
  return POST('salesforce/connectSetup', data)
}

const restoreSnapshot = ({
                           snapshotDateTime,
                           sourceIds,
                           searchTerm,
                           destinationType,
                           snapshotId,
                           selectedIds,
                           RestoreType,
                           code
                         }) => {
  return POST('salesforce/restore', {
    snapshotDateTime,
    sourceIds,
    searchTerm,
    destinationType,
    snapshotId,
    RestoreType,
    selectedIds
  }, {code: code ? code : null});
}

const compareSnapshot = ({sourceIds, selectedIds, objectType, firstSnapshotDateTime, secondSnapshotDateTime, code}) => {
  return POST('salesforce/compare', {
    sourceIds,
    selectedIds,
    objectType,
    firstSnapshotDateTime,
    secondSnapshotDateTime
  }, {code: code ? code : null});
}

export {
  listSnapshots,
  createBackup,
  exportSnapshot,
  connectSetup,
  restoreSnapshot,
  compareSnapshot,
}
