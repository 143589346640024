import {DELETE, POST, GET} from "@/services/gateway";

const connectAws = (data) => {
  return POST('recovery/destination/awss3/connect', data);
}

const connectAzure = (data) => {
  return POST('recovery/destination/azure/connect', data);
}

const connectDropbox = (data) => {
  return POST('recovery/destination/dropbox/connect', data);
}

const listConnection = (data) => {
  return POST('recovery/destination/list', data);
}

const listRegion = () => {
  return GET('recovery/destination/awss3/regions');
}

const deleteConnection = (id) => {
  return DELETE(`recovery/destination`, id)
}

const connectGoogle = (data) => {
  return POST('recovery/destination/google/connect', data);
}

const connectMicrosoft = (data) => {
  return POST('recovery/destination/microsoft/connect', data);
}

const connectBox = (data) => {
  return POST('recovery/destination/box/connect', data);
}

export {
  connectAzure,
  connectAws,
  connectDropbox,
  listRegion,
  listConnection,
  deleteConnection,
  connectGoogle,
  connectMicrosoft,
  connectBox
}
