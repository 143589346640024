import {required} from "vuelidate/lib/validators";

const azureConnection = (
  {
    rows: [
      {
        blocks: [
          {
            fields: [
              {
                name: 'containerName',
                type: 'text',
                placeholder: 'Container name'
              },
              {
                name: 'accountName',
                type: 'text',
                placeholder: 'Account name'
              },
              {
                name: 'accountKey',
                type: 'text',
                placeholder: 'Account key'
              },
            ]
          },
        ]
      }
    ],
    validations: {
      containerName: {
        required
      },
      accountName: {
        required
      },
      accountKey: {
        required
      },

    },
    errors: {
      containerName: {
        required: "Container name is required",
      },
      accountName: {
        required: "Account name is required",
      },
      accountKey: {
        required: "Account key is required",
      },
    }
  }
)


export {
  azureConnection,
}
