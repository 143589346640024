import {defaultPagination, gridPagination} from "@/services/pagination";
import {
  childRelationships,
  createSeedingTemplate, deleteTemplate,
  editSeedingTemplate, favorites, getRootObjects,
  getTemplateById,
  listTemplates, parentRelationships, publishTemplate, changeEditMode, exportTemplate, cloneTemplate, importTemplate
} from "@/services/api/app/salesforce/recovery/seeding/seeding"
import {getTemplateTypes} from "@/services/enums";

const defaultState = () => ({
  templates: [],
  templateDetails: {},
  pagination: {
    ...defaultPagination(),
    sortBy: 'name',
  },
  gridPagination: {
    ...gridPagination(),
    sortBy: 'name',
  },
  search: {
    searchTerm: ""
  },
  isFavorite: null,
  parentRelationshipsData: null,
  childRelationshipsData: null
})

export default {
  namespaced: true,

  actions: {
    listTemplates({ commit, state }, data) {
      return listTemplates({
        ...data,
        pagination: state.pagination,
        searchTerm: data.searchTerm ? data.searchTerm : state?.search?.searchTerm,
        filter: null
      })
        .then(({ result, pagination }) => {
          commit('setTemplates', result);
          commit('setPagination', pagination);
        })
        .catch(error => {
          console.error("Failed to list templates:", error);
          throw error;
        });
    },

    listTemplatesGridView({ commit, state }, data) {
      return listTemplates({
        ...data,
        pagination: data.pagination,
        searchTerm: data.searchTerm || state.search.searchTerm,
        filter: null,
      })
        .then(({ result, pagination }) => {
          const updatedTemplates = result.map((template) => ({
            ...template
          }));

          if (data.pagination?.pageNumber > 1) {
            commit("appendTemplates", updatedTemplates);
          } else {
            commit("setTemplates", updatedTemplates);
          }

          commit("setGridPagination", pagination);
        })
        .catch((error) => {
          console.error("Failed to list templates:", error);
          throw error;
        });
    },

    createSeedingTemplate({commit, dispatch, state}, data) {
      return createSeedingTemplate({
        ...data
      })
        .then(() => {
          return dispatch('listTemplates', {
            searchTerm: data.searchTerm ? data.searchTerm : state?.search?.searchTerm,
            pagination: state.pagination,
            filter: null
          });
        })
        .catch((error) => {
          console.error("Failed to create template:", error);
          throw error;
        });
    },

    editTemplate({ commit, dispatch, state }, data) {
      return editSeedingTemplate({
        ...data,
      })
        .then((template) => {
          commit("setTemplateDetails", template);

          return template;
        })
        .catch((error) => {
          console.error("Failed to edit template:", error);
          throw error;
        });
    },

    getTemplateById({ commit }, id) {
      return getTemplateById({ id })
        .then((template) => {
          commit("setTemplateDetails", template);
          return template;
        })
        .catch((error) => {
          console.error("Failed to fetch template by ID:", error);
          throw error;
        });
    },

    publishTemplate({ commit }, id) {
      return publishTemplate({ id })
        .then((template) => {
          commit("setTemplateDetails", template);

          return template;
        })
        .catch((error) => {
          console.error("Failed to publish template:", error);
          throw error;
        });
    },

    changeEditMode({ commit }, id) {
      return changeEditMode({ id })
        .then((template) => {
          commit("setTemplateDetails", template);

          return template;
        })
        .catch((error) => {
          console.error("Failed to publish template:", error);
          throw error;
        });
    },

    getFavorites({ commit }, id) {
      return favorites({ id })
        .then((favorites) => {
          commit("setFavorites", favorites);
          return favorites;
        })
        .catch((error) => {
          console.error("Failed to add to favorites:", error);
          throw error;
        });
    },

    getRoots({ commit }) {
      return getRootObjects().then((roots) => {
        commit("setRoots", roots);
        return roots;
      }).catch((error) => {
        console.error("Failed to get roots:", error);
        throw error;
      })
    },

    getParentRelationships({ commit }, { objectName, snapshotDateTime }) {
      return parentRelationships({ objectName, snapshotDateTime })
        .then((data) => {
          commit("setParentRelationshipsData", data);
          return data;
        })
        .catch((error) => {
          console.error("Failed to get parent relationships:", error);
          throw error;
        });
    },

    getChildRelationships({ commit }, { objectName, snapshotDateTime }) {
      return childRelationships({ objectName, snapshotDateTime })
        .then((data) => {
          commit("setChildRelationshipsData", data);
          return data;
        })
        .catch((error) => {
          console.error("Failed to get child relationships:", error);
          throw error;
        });
    },

    deleteTemplate({ commit, state }, {id}) {
      return deleteTemplate(id)
    },

    exportTemplate({ commit, state }, {id}) {
      return exportTemplate(id)
    },

    cloneTemplate({ commit, state }, {id}) {
      return cloneTemplate(id)
    },

    importTemplate({ commit }, { id, file }) {
      return importTemplate(id, file)
        .then(response => {
          commit('setTemplateDetails', response);
          return response;
        });
    },
  },

  mutations: {
    setTemplates(state, templates) {
      state.templates = templates;
    },
    setTemplateDetails(state, templateDetails) {
      state.templateDetails = templateDetails;
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    setGridPagination(state, pagination) {
      state.gridPagination = pagination;
    },
    setSearch(state, search) {
      state.search = search
    },
    setFavorites(state, { id, isFavorite }) {
      const template = state.templates.find((t) => t.id === id);
      if (template) {
        template.isFavorite = isFavorite;
      }
    },
    setRoots(state, roots) {
      state.roots = roots;
    },
    setParentRelationshipsData(state, data) {
      state.parentRelationshipsData = data;
    },
    setChildRelationshipsData(state, data) {
      state.childRelationshipsData = data;
    },
    resetPagination(state, newPagination = defaultPagination()) {
      state.pagination = newPagination;
    },
    resetGridPagination(state, newPagination = gridPagination()) {
      state.gridPagination = newPagination;
    },
    resetState(state) {
      Object.assign(state, defaultState())
    },
    appendTemplates(state, templates) {
      state.templates = [...state.templates, ...templates];
    },
  },
  getters: {
    getAllTemplates(state) {
      return state.templates.map(template => ({
        ...template,
        templateTypeLabel: getTemplateTypes(template.templateType)
      }));
    }
  },
  state: defaultState()
}
