import {
  compareSnapshot,
  createBackup,
  exportSnapshot,
  listSnapshots,
  restoreSnapshot,
} from "@/services/api/app/salesforce/recovery/salesforceObject/object";
import {
  connectAws,
  connectAzure,
  connectDropbox,
  deleteConnection,
  listConnection,
  listRegion,
  connectGoogle,
  connectMicrosoft,
  connectBox
} from "@/services/api/common/connection";
import files from "@/store/app/salesforce/recovery/object/data-set/file";
import exports from "@/store/app/salesforce/recovery/object/exports";
import restores from "@/store/app/salesforce/recovery/object/restores";
import backups from "@/store/app/salesforce/recovery/object/backups";
import compares from "@/store/app/salesforce/recovery/object/compares";
import seeding from "@/store/app/salesforce/recovery/seeding";

const defaultState = () => ({
  userMap: {},
  listRegions: {},
});

export default {
  namespaced: true,
  actions: {
    createBackup({commit}) {
      return createBackup();
    },
    listSnapshots({commit}, {date, dateTo}) {
      return listSnapshots({date, dateTo});
    },
    exportSnapshot({commit}, data) {
      return exportSnapshot(data);
    },
    connectAws({commit}, data) {
      return connectAws(data);
    },
    connectAzure({commit}, data) {
      return connectAzure(data);
    },
    connectDropbox({commit}, data) {
      return connectDropbox(data);
    },
    connectGoogle({commit}, data) {
      return connectGoogle(data);
    },
    connectMicrosoft({commit}, data) {
      return connectMicrosoft(data);
    },
    connectBox({commit}, data) {
      return connectBox(data);
    },
    listConnection({commit}, data) {
      return listConnection(data);
    },
    deleteConnection({commit, state}, {id}) {
      return deleteConnection(id)
    },
    listRegion({commit, state}) {

      return listRegion()
        .then(region => {
          commit('setRegion', region);
          return region;
        })
    },
    restoreSnapshot({}, data) {
      return restoreSnapshot(data);
    },
    compareSnapshot({}, data) {
      return compareSnapshot(data);
    },
  },

  mutations: {
    setUser(state, user) {
      state.userMap = {
        ...state.userMap,
        [user.userId]: user
      };
    },
    setRegion(state, regions) {
      state.listRegions = regions
    },
  },

  getters: {
    getUserById: (state) => (id) => {
      return state.userMap[id];
    },
    getListRegions: (state) => () => {
      if (Array.isArray(state.listRegions)) {
        return state.listRegions.map(region => ({
          ...region,
          label: region.displayName,
          value: region.systemName
        }));
      } else {
        return [];
      }
    },
  },

  state: defaultState(),

  modules: {
    files,
    exports,
    restores,
    backups,
    compares,
    seeding
  }
}
