import {required} from "vuelidate/lib/validators";

const awsConnection = (regionOptions, regionPlaceholder) => (
  {
    rows: [
      {
        blocks: [
          {
            fields: [
              {
                name: 'bucketName',
                type: 'text',
                placeholder: 'Bucket name'
              },
              {
                name: 'accessKey',
                type: 'text',
                placeholder: 'Access key'
              },
              {
                name: 'secretKey',
                type: 'text',
                placeholder: 'Secret key'
              },
              {
                name: 'region',
                label: '',
                type: 'i-dropdown',
                placeholder: regionPlaceholder,
                allowEmpty: false,
                options: regionOptions
              },
            ]
          },
        ]
      }
    ],
    validations: {
      bucketName: {
        required
      },
      accessKey: {
        required
      },
      secretKey: {
        required
      },
      region: {
        required
      },

    },
    errors: {
      bucketName: {
        required: "Bucket name is required",
      },
      accessKey: {
        required: "Access key is required",
      },
      secretKey: {
        required: "Secret key is required",
      },
      region: {
        required: "Region is required",
      },
    }
  })


export {
  awsConnection,
}
