import salesforce from "@/store/app/salesforce/recovery/object/object";
import jobs from "@/store/app/salesforce/recovery/jobs";


export default {
  namespaced: true,

  actions: {},

  modules: {
    salesforce,
    jobs
  }
}
