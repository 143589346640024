<template>
  <div class="i-radio-buttons i-destination-picker" :class="vertical">
    <div class="field-label" v-if="label && optionFormatted.length > 0">
      {{ label }}
    </div>

    <div class="radio-options" v-for="option in optionFormatted" :key="option.value">
      <label :class="{'disabled-label' : option.disabled}">
        <input
          type="radio"
          :name="name"
          :checked="option.value === value"
          :value="option.value"
          :disabled="option.disabled"
          @input="onInput"
        />
        {{ option.label }}
      </label>
      <span class="submit-icon" @click="openDeleteDialog(option.id)">
        <i-generate-image
          image="icons/cancel-icon.svg"
        />
      </span>
    </div>

    <i-button
      class="submit-button border bold setup-new-connection-btn"
      label="Setup new connection"
      @click.stop.prevent="setupNewConnection()"
    />

    <form-builder
      v-if="showSetup"
      :value="formData"
      button-label="Connect"
      :config="globalSetupConnection"
      :submit-action="submitAction"
    />

    <div class="field-hint" v-if="hint">
      {{ hint }}
    </div>

    <i-confirmation-dialog
      ref="deleteConnectionModal"
      title="Delete connection"
      class="delete-confirmation-dialog"
      :confirm-action="deleteConnection"
    >
      <template v-slot:idialog-body>
        Are you sure you want to cancel this job?
      </template>
    </i-confirmation-dialog>
  </div>
</template>

<script>
import IGenerateImage from "@/components/common/IGenerateImage.vue";
import IButton from "@/components/common/IButton.vue";
import {setupConnection} from "@/services/forms/app/recovery/export"
import IConfirmationDialog from "@/components/common/dialog/IConfirmationDialog.vue";
import {mapGetters} from "vuex";

export default {
  name: "IDestinationPicker",
  components: {IConfirmationDialog, IButton, IGenerateImage},
  props: {
    data: {
      type: Object
    },
    label: {
      type: String
    },
    hint: {
      type: String,
    },
    name: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean
    },
    vertical: {
      type: String, // horizontal|vertical
    },
    storageDestinationType: {
      type: [String, Number],
    }
  },

  data() {
    return {
      showSetup: false,
      formData: null,
      connectionId: null,
      isLoading: false,
      regionOption: []
    }
  },
  computed: {
    ...mapGetters({
      getFormatedRegions: "salesforce/recovery/salesforce/getListRegions",
    }),
    globalSetupConnection() {
      return setupConnection(this.storageDestinationType, this.getFormatedRegions(), 'Region')
    },
    optionFormatted() {
      return this.options.map(option => ({
        label: this.getOptionLabel(option),
        value: this.getOptionValue(option),
        id: option.id,
      }))
    },
  },
  methods: {
    onInput(e) {
      this.$emit('input', e.target.value);
    },
    openDeleteDialog(id) {
      this.connectionId = id;
      this.$refs['deleteConnectionModal'].openModal();
    },
    deleteConnection() {
      return this.$store.dispatch(`salesforce/recovery/salesforce/deleteConnection`, {id: this.connectionId})
        .then(() => {
          this.$emit('refresh')
        })
    },
    setupNewConnection() {
      if (this.storageDestinationType == 3) {
        return this.$store.dispatch(`salesforce/recovery/salesforce/connectGoogle`, {ProviderTenantType: this.$providerTenantVal})
        .then((data) => {
          window.open(data.authorizeUrl);
        })
      }
      else if (this.storageDestinationType == 4) {
        return this.$store.dispatch(`salesforce/recovery/salesforce/connectMicrosoft`, {ProviderTenantType: this.$providerTenantVal})
        .then((data) => {
          window.open(data.authorizeUrl);
        })
      }
      else if (this.storageDestinationType == 5) {
        return this.$store.dispatch(`salesforce/recovery/salesforce/connectDropbox`, {ProviderTenantType: this.$providerTenantVal})
        .then((data) => {
          window.open(data.authorizeUrl);
        })
      }
      else if (this.storageDestinationType == 6) {
        return this.$store.dispatch(`salesforce/recovery/salesforce/connectBox`, {ProviderTenantType: this.$providerTenantVal})
        .then((data) => {
          window.open(data.authorizeUrl);
        })
      }
      else {
        this.showSetup = !this.showSetup

        return this.$store.dispatch(`salesforce/recovery/salesforce/listRegion`)
        .then((data) => {
          this.regionOption = data;
        })
      }
    },
    wathcForParentMessage(e) {
      try {
        const responseObj = JSON.parse(e.data);
        console.log('responseObj', responseObj);
        if (responseObj.success == true) {
          this.$emit('refresh')
        }
      }
      catch(e) {

      }
    },
    submitAction(formData) {
      this.isLoading = true;

      this.formData = {
        ...formData,
        providerTenantType: this.$providerTenantVal,
        storageDestinationType: this.storageDestinationType,
      }

      const connectionModules = {
        1: 'connectAws',
        2: 'connectAzure',
        5: 'connectDropbox'
      };

      const connectionModule = connectionModules[this.storageDestinationType] || '';

      return this.$store.dispatch(`salesforce/recovery/salesforce/${connectionModule}`, this.formData)
        .then(() => {
          this.isLoading = false;
          this.showSetup = false;
          this.$emit('refresh')
        })
    },
    getOptionLabel(option) {
      if ([3, 4, 5, 6].includes(option.storageDestinationType)) {
        return option.email;
      }
      else if (option.storageDestinationType == 1) {
        return option.bucketName;
      }
      else if (option.storageDestinationType == 2) {
        return option.containerName;
      }

      return 'NA';
    },
    getOptionValue(option) {
      return option.id;

      // if ([3, 4, 5, 6].includes(option.storageDestinationType)) {
      //   return option.id;
      // }
      // else if (option.storageDestinationType == 1) {
      //   return option.accessKey;
      // }
      // else if (option.storageDestinationType == 2) {
      //   return option.accountKey;
      // }

      // return 'NA';
    }
  },
  created() {
    window.addEventListener('message', this.wathcForParentMessage)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.wathcForParentMessage)
  },
  watch: {
    storageDestinationType(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.formData = null;
        this.showSetup = false;
      }
    }
  }
}
</script>

<style lang="scss">
@import "IDestinationPicker";
</style>
