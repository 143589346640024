import {
  getStats,
  generateSalesforceReauthUrl
} from "@/services/api/app/salesforce/stats";

const defaultState = () => ({
  stats: {},
});

export default {
  namespaced: true,

  actions: {
    getStats({commit}, data) {
      return getStats(data)
        .then(stats => {
          commit('setStats', stats);
          return stats;
        })
    },
    getReconnectUrl({commit}) {
      const testtt = generateSalesforceReauthUrl();

      console.log('testtt', testtt);
      return testtt;
    },
  },

  mutations: {
    setStats(state, stats) {
      state.stats = stats
    },
  },

  getters: {
    getTotalProtectedData: (state) => () => {
      const
        driveStats = state.stats?.driveStats?.totalData || 0,
        contactStats = state.stats?.contactStats?.totalData || 0,
        googleMailStats = state.stats?.googleMailStats?.totalData || 0,
        calendarStats = state.stats?.calendarStats?.totalData || 0,
        googleSharedDriveStats = state.stats?.googleSharedDriveStats?.totalData || 0,

        totalData = driveStats + contactStats + googleMailStats + calendarStats + googleSharedDriveStats

      return totalData
    },
  },

  state: defaultState(),
}
