<template>
  <div class="i-confirmation-dialog" @click="$ev =>$emit('click', $ev)">
    <vs-modal
      ref="backdrop-modal"
      backdropBlur
      dismiss-on="esc"
      :removeHeader="true"
      :title="title"
    >
      <div class="vs-modal__header">
        <h2 class="vs-modal__header-text">
          {{ title }}
        </h2>
        <img
          class="closeDialogButton"
          alt="dialog close button"
          :src="closeButton"
          @click="$refs['backdrop-modal'].close()"
        >
      </div>
      <slot name="idialog-body"></slot>

      <div slot="footer" class="footer-btn-container">
        <i-button
          btn-class="cancel-btn border"
          :is-disabled="isButtonLoading"
          :label="closeBtn"
          @click.stop.prevent="closeModal('backdrop-modal')"
        />
        <i-button
          btn-class="delete-btn border"
          :label="submitBtn"
          :is-disabled="isDisabled"
          :is-loading="isButtonLoading"
          @click.stop.prevent="confirm('backdrop-modal')"
        />
      </div>
    </vs-modal>
  </div>
</template>

<script>

import VsModal from '@vuesimple/vs-modal';
import IButton from "@/components/common/IButton";

export default {
  name: 'IConfirmationDialog',
  components: {IButton, VsModal},

  props: {
    label: {
      type: String,
      default: 'open'
    },
    title: {
      type: String,
    },
    closeBtn: {
      type: String,
      default: 'No'
    },
    submitBtn: {
      type: String,
      default: 'Yes'
    },
    confirmAction: {
      type: Function,
      required: true,
    },
    closeOnError: {
      type: Boolean,
      default: false
    },
    isButtonLoading: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
  },

  data: () => ({error: null, isLoading: false}),

  computed: {
    closeButton() {
      if (this.$isDark) {
        return require(`../../../assets/app/icons/close-icon.svg`);
      }
      return require(`../../../assets/app/icons/close-icon-dark.svg`);
    },
  },

  methods: {

    openModal() {
      this.$refs['backdrop-modal'].open();
    },

    closeModal(ref) {
      this.$refs['backdrop-modal'].close();
    },

    confirm(ref) {
      this.isLoading = true
      this.error = null;
      return this.confirmAction()
        .then(() => {
          if (this.successMessage) {
            // this.$notify({
            //   group: 'foo',
            //   title: 'Important message',
            //   text: 'Hello user! This is a notification!'
            // });
          }
          this.$refs[ref].close();
        }).catch(err => {
          if (this.closeOnError) {
            // this.$handleGqlError(err)
            this.$refs[ref].close();
          } else {
            // this.error = this.$getFirstGqlErrorKey(err);

          }
        })
        .finally(() => this.isLoading = false)
    },
  }
}
</script>

<style lang="scss">
@import "IConfirmationDialog";
</style>
