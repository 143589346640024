import recovery from "@/store/app/salesforce/recovery/recovery";
import editDomain from "@/store/app/salesforce/editDomain";
import stats from "@/store/app/salesforce/stats";
import seats from "@/store/app/salesforce/seats";

const defaultState = () => ({})

export default {
  namespaced: true,

  actions: {},

  modules: {
    recovery: recovery,
    editDomain,
    stats,
    seats,
  }
}
