import {editDomain} from "@/services/api/app/common/editDomain";

const defaultState = () => ({
  domain: '',
  providerTenantType: 3,
});

export default {
  namespaced: true,
  actions: {
    getDomain({commit, state}, domain) {
      return editDomain({
        Domain: domain,
        providerTenantType: state.providerTenantType
      })
        .then(() => {
          commit('setDomain')

          return domain;
        })

    },
  },

  mutations: {
    setDomain(state, domain) {
      state.domain = domain;
    },
  },

  state: defaultState(),
}
