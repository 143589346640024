import {DELETE, GET, POST} from "@/services/gateway";

export const createSeedingTemplate = ({name, description, templateType, isFavorite = false}) => {
  return POST('seeding/create-template', {name, description, templateType, isFavorite})
}

export const editSeedingTemplate = ({id, name, description, templateType, isFavorite, isInEditMode, draftTemplate, publishedTemplate, publishedAt, createdBy}) => {
  return POST('seeding/edit-template', {id, name, description, templateType, isFavorite, isInEditMode, draftTemplate, publishedTemplate, publishedAt, createdBy})
}

export const listTemplates = ({pagination, searchTerm, templateType}) => {
  return POST('seeding/list-templates', {pagination, searchTerm, templateType})
}

export const parentRelationships = ({objectName, snapshotDateTime}) => {
  return POST(`salesforce/parent-relationships`, {objectName, snapshotDateTime})
}

export const childRelationships = ({objectName, snapshotDateTime}) => {
  return POST(`salesforce/child-relationships`, {objectName, snapshotDateTime})
}

export const getRootObjects = () => {
  return GET('salesforce/objects')
}

export const getTemplateById = ({id}) => {
  return GET(`seeding/template`, {id})
}

export const favorites = ({id}) => {
  return GET('seeding/favorites', {id})
}

export const publishTemplate = ({id}) => {
  return POST(`seeding/publish-template?id=${id}`, {id})
}

export const changeEditMode = ({id}) => {
  return POST(`seeding/set-template-edit-mode?id=${id}`, {id})
}

export const deleteTemplate = (id) => {
  return DELETE(`seeding/delete-template`, id)
}

export const cloneTemplate = (id) => {
  return POST(`seeding/clone-template?id=${id}`)
}

export const exportTemplate = (id) => {
  return POST(`seeding/export-template?id=${id}`)
}

export const importTemplate = (id, data) => {
  return POST(`seeding/import-template?id=${id}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  });
};
