<template>
  <div class="form-builder-field">
    <i-checkbox
      v-if="field.type === 'i-checkbox'"
      :label="field.label"
      :value="fieldValue"
      :name="field.name"
      :vertical="field.vertical"
      :options="field.options"
      @input="onSelected($event, field.name)"
    />

    <i-radio-buttons
      v-else-if="field.type === 'i-radio-buttons'"
      :label="field.label"
      :label-information="field.labelInformation"
      :hide-radio="field.hideRadio"
      :hint="field.hint"
      :value="fieldValue"
      :name="field.name"
      :vertical="field.vertical"
      :options="field.options"
      @input="onInput($event, field.name)"
    />

    <i-destination-picker
      v-else-if="field.type === 'i-destination-picker'"
      :label="field.label"
      :hint="field.hint"
      :value="fieldValue"
      :name="field.name"
      :vertical="field.vertical"
      :options="field.options"
      :storage-destination-type="field.storageDestinationType"
      @input="onInput($event, field.name)"
      @refresh="$emit('refresh')"
    />

    <i-dropdown
      v-else-if="field.type === 'i-dropdown'"
      :label="field.label"
      :value="fieldValue"
      :required="field.required"
      :multi-select="field.multiSelect"
      :placeholder="field.placeholder"
      :name="field.name"
      :text-transform="field.textTransform"
      :clearable="field.clearable"
      :options="field.options"
      :taggable="field.taggable"
      :searchable="field.searchable"
      :disabled="field.disabled"
      :allow-empty="field.allowEmpty"
      @input="onInput($event, field.name)"
    />

    <i-backup-status-dropdown
      v-else-if="field.type === 'i-backup-status-dropdown'"
      :label="field.label"
      :value="fieldValue"
      :required="field.required"
      :required-label="field.requiredLabel"
      :name="field.name"
      :options="field.options"
      @input="onInput($event, field.name)"
    />

    <i-backup-salesforce-status-dropdown
      v-else-if="field.type === 'i-backup-salesforce-status-dropdown'"
      :label="field.label"
      :value="fieldValue"
      :required="field.required"
      :required-label="field.requiredLabel"
      :name="field.name"
      :options="field.options"
      @input="onInput($event, field.name)"
    />

    <i-country-picker
      v-else-if="field.type === 'i-country-picker'"
      :label="field.label"
      :value="fieldValue"
      :name="field.name"
      :options="field.options"
      @input="onInput($event, field.name)"
    />

    <i-user-picker
      v-else-if="field.type === 'i-user-picker'"
      :label="field.label"
      :value="fieldValue"
      :name="field.name"
      :options="field.options"
      :filter="field.filter"
      :placeholder="field.placeholder"
      :provider-tenant="field.providerTenant"
      :current-collection="field.currentCollection"
      @input="onInput($event, field.name)"
    />

    <i-site-picker
      v-else-if="field.type === 'i-site-picker'"
      :label="field.label"
      :value="fieldValue"
      :name="field.name"
      :options="field.options"
      :filter="field.filter"
      :placeholder="field.placeholder"
      @input="onInput($event, field.name)"
    />

    <i-team-picker
      v-else-if="field.type === 'i-team-picker'"
      :label="field.label"
      :value="fieldValue"
      :name="field.name"
      :options="field.options"
      :filter="field.filter"
      :placeholder="field.placeholder"
      @input="onInput($event, field.name)"
    />


    <i-shared-drive-picker
      v-else-if="field.type === 'i-shared-drive-picker'"
      :label="field.label"
      :value="fieldValue"
      :name="field.name"
      :options="field.options"
      :filter="field.filter"
      :placeholder="field.placeholder"
      @input="onInput($event, field.name)"
    />

    <i-shared-folder-picker
      v-else-if="field.type === 'i-shared-folder-picker'"
      :label="field.label"
      :value="fieldValue"
      :name="field.name"
      :options="field.options"
      :filter="field.filter"
      :placeholder="field.placeholder"
      @input="onInput($event, field.name)"
    />

    <i-select
      v-else-if="field.type === 'i-select'"
      :label="field.label"
      :value="fieldValue"
      :options="field.options"
      :clearable="field.clearable"
      :searchable="field.searchable"
      :option-value="field.optionValue"
      :option-label="field.optionLabel"
      :emit-value="field.emitValue"
      @input="onInput($event, field.name)"
    />

    <i-demo-picker
      v-else-if="field.type === 'i-demo-picker'"
      :label="field.label"
      :value="fieldValue"
      :options="field.options"
      :clearable="field.clearable"
      :searchable="field.searchable"
      :option-value="field.optionValue"
      :option-label="field.optionLabel"
      :emit-value="field.emitValue"
      @input="onInput($event, field.name)"
    />

    <i-switcher
      v-else-if="field.type === 'i-switcher'"
      :label="field.label"
      :value="fieldValue"
      @input="onInput($event, field.name)"
    />

    <i-date-time
      v-else-if="field.type === 'i-date-time'"
      :placeholder="field.label"
      :value="fieldValue"
      :is-required="field.isRequired"
      :min-date="field.minDate"
      :max-date="field.maxDate"
      @input="onInput($event, field.name)"
    />

    <i-date
      v-else-if="field.type === 'i-date'"
      :placeholder="field.label"
      :value="fieldValue"
      :is-required="field.isRequired"
      :is-all-day="field.isAllDay"
      :custom-max-date="field.customMaxDate"
      :custom-selected-date="field.customSelectedDate"
      @input="onInput($event, field.name)"
    />

    <i-user-multi-picker
      v-else-if="field.type === 'i-user-multi-picker'"
      :label="field.label"
      :value="fieldValue"
      :name="field.name"
      :options="field.options"
      :placeholder="field.placeholder"
      :provider-tenant="field.providerTenant"
      :current-collection="field.currentCollection"
      @input="onInput($event, field.name)"
    />

    <i-site-multi-picker
      v-else-if="field.type === 'i-site-multi-picker'"
      :label="field.label"
      :value="fieldValue"
      :name="field.name"
      :placeholder="field.placeholder"
      :options="field.options"
      @input="onInput($event, field.name)"
    />

    <i-team-multi-picker
      v-else-if="field.type === 'i-team-multi-picker'"
      :label="field.label"
      :value="fieldValue"
      :name="field.name"
      :placeholder="field.placeholder"
      :options="field.options"
      @input="onInput($event, field.name)"
    />

    <i-shared-drive-multi-picker
      v-else-if="field.type === 'i-shared-drive-multi-picker'"
      :label="field.label"
      :value="fieldValue"
      :name="field.name"
      :placeholder="field.placeholder"
      :options="field.options"
      @input="onInput($event, field.name)"
    />

    <i-shared-folder-multi-picker
      v-else-if="field.type === 'i-shared-folder-multi-picker'"
      :label="field.label"
      :value="fieldValue"
      :name="field.name"
      :placeholder="field.placeholder"
      :options="field.options"
      @input="onInput($event, field.name)"
    />

    <i-object-multi-picker
      v-else-if="field.type === 'i-object-multi-picker'"
      :label="field.label"
      :value="fieldValue"
      :name="field.name"
      :snapshotId="field.snapshotId"
      :options="field.options"
      :placeholder="field.placeholder"
      :provider-tenant="field.providerTenant"
      :current-collection="field.currentCollection"
      @input="onInput($event, field.name)"
    />

    <i-seat-multi-picker
      v-else-if="field.type === 'i-seat-multi-picker'"
      :label="field.label"
      :value="fieldValue"
      :name="field.name"
      :options="field.options"
      :placeholder="field.placeholder"
      :provider-tenant="field.providerTenant"
      :disabled="field.disabled"
      @input="onInput($event, field.name)"
    />

    <i-search-organisation-picker
      v-else-if="field.type === 'i-search-organisation-picker'"
      :label="field.label"
      :value="fieldValue"
      :name="field.name"
      :placeholder="field.placeholder"
      :options="field.options"
      :clearable="field.clearable"
      @input="onInput($event, field.name)"
    />

    <i-textarea
      v-else-if="field.type === 'i-textarea'"
      :ref="field.name"
      :label="field.label"
      :placeholder="field.placeholder"
      :value="fieldValue"
      :name="field.name"
      @input="onInput($event, field.name)"
    />

    <i-input
      v-else
      :ref="field.name"
      :label="field.label"
      :placeholder="field.placeholder"
      :value="fieldValue"
      :name="field.name"
      :field-type="field.type"
      :icon-position="field.iconPosition"
      :icon="field.icon"
      :disabled="field.disabled"
      :icon-image="field.iconImage"
      :is-icon-clickable="field.isIconClickable"
      :debounce="field.debounce"
      :min-length="field.minLength"
      :max-length="field.maxLength"
      :disable-arrows="field.disableArrows"
      :autofocus="field.autofocus"
      @input="onInput($event, field.name)"
    />

  </div>
</template>

<script>

import IInput from "@/components/common/IInput";
import IRadioButtons from "@/components/common/IRadioButtons";
import ISwitcher from "@/components/common/ISwitcher";
import ICheckbox from "@/components/common/forms/ICheckbox";
import IDropdown from "@/components/common/IDropdown";
import ISelect from "@/components/common/ISelect";
import IDemoPicker from "@/components/common/IDemoPicker";
import ICountryPicker from "@/components/common/forms/ICountryPicker";
import IUserPicker from "@/components/common/forms/IUserPicker";
import ISitePicker from "@/components/common/forms/ISitePicker";
import IDateTime from "@/components/common/forms/IDateTime";
import IDate from "@/components/common/forms/IDate";
import IUserMultiPicker from "@/components/common/forms/IUserMultiPicker";
import ISiteMultiPicker from "@/components/common/forms/ISiteMultiPicker";
import ITextarea from "@/components/common/ITextarea";
import ITeamMultiPicker from "@/components/common/forms/ITeamMultiPicker";
import ITeamPicker from "@/components/common/forms/ITeamPicker";
import IBackupStatusDropdown from "@/components/common/forms/IBackupStatusDropdown";
import ISearchOrganisationPicker from "@/components/common/forms/ISearchOrganisationPicker";
import ISharedDrivePicker from "@/components/common/forms/ISharedDrivePicker";
import ISharedDriveMultiPicker from "@/components/common/forms/ISharedDriveMultiPicker";
import ISeatMultiPicker from "@/components/common/forms/ISeatMultiPicker";
import ISharedFolderPicker from "@/components/common/forms/ISharedFolderPicker";
import ISharedFolderMultiPicker from "@/components/common/forms/ISharedFolderMultiPicker";
import IObjectMultiPicker from "@/components/common/forms/IObjectMultiPicker";
import IBackupSalesforceStatusDropdown from "@/components/app/salesforce/IBackupSalesforceStatusDropdown";
import IDestinationPicker from "@/components/app/common/recovery/IDestinationPicker";

export default {
  name: "FormBuilderField",
  components: {
    IDestinationPicker,
    IObjectMultiPicker,
    ISharedFolderMultiPicker,
    ISharedFolderPicker,
    ISeatMultiPicker,
    ISharedDrivePicker,
    IBackupStatusDropdown,
    ITeamPicker,
    ITeamMultiPicker,
    ISiteMultiPicker,
    IUserMultiPicker,
    ISharedDriveMultiPicker,
    ITextarea,
    IDateTime,
    IDate,
    ISitePicker,
    IUserPicker,
    ICountryPicker,
    IDemoPicker,
    ISelect,
    IDropdown,
    IInput,
    IRadioButtons,
    ISwitcher,
    ICheckbox,
    ISearchOrganisationPicker,
    IBackupSalesforceStatusDropdown
  },

  props: {
    field: {
      type: Object,
      required: true
    },
    fieldValue: {},
    initialFieldValue: {}
  },

  methods: {
    onInput(value, fieldName) {
      if (this.field.type === 'email') {
        this.$emit('input', {value: value.trim().toLowerCase(), field: fieldName})
      } else {
        this.$emit('input', {value: value, field: fieldName})
      }
    },

    onSelected(value, fieldName) {
      if (!this.fieldValue) {
        this.$emit('input', {
          value: [value],
          field: fieldName
        })
      } else {
        let selectedValues = this.fieldValue

        if (this.fieldValue.includes(value)) {
          selectedValues = selectedValues.filter(v => v !== value)
        } else {
          selectedValues = [
            ...selectedValues,
            value
          ]
        }
        this.$emit('input', {
          value: selectedValues,
          field: fieldName
        })
      }

    },
  },
}
</script>

<style lang="scss">
@import "FormBuilderField";
</style>
