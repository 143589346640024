import {defaultPagination} from "@/services/pagination";
import {listSeats} from "@/services/api/app/salesforce/seats"

const defaultState = () => ({
  seats: [],
  pagination: {
    ...defaultPagination(),
    sortBy: 'firstName',
  },
})

export default {
  namespaced: true,
  actions: {
    listSeats({ commit, state }, data) {
      return listSeats({
        ...data,
        pagination: state.pagination,
      })
        .then(({ result, pagination }) => {
          commit('setSeats', result);
          commit('setPagination', pagination);
        })
        .catch(error => {
          console.error("Failed to list seats:", error);
          throw error;
        });
    },
  },
  mutations: {
    setSeats(state, seats) {
      state.seats = seats;
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    resetPagination(state, newPagination = defaultPagination()) {
      state.pagination = newPagination;
    },
    resetState(state) {
      Object.assign(state, defaultState())
    },
  },

  state: defaultState()
}
