import {GET, POST} from "@/services/gateway";

const getStats = (data) => {
  const currentDate = new Date();
  data = {
    ...data,
    TimeZoneOffset: currentDate.getTimezoneOffset()
  }
  return POST('dashboard/stats/salesforce', data)
}

const generateSalesforceReauthUrl = () => {
  return GET('tenant/salesforce/reconnect')
}

export {
  getStats,
  generateSalesforceReauthUrl
}
