import {defaultPagination} from "@/services/pagination";
import {getTemplateTypes, getStatusLabels} from "@/services/enums";
import {listSeedingJobs} from "@/services/api/app/salesforce/recovery/seeding/jobs";

const defaultState = () => ({
  seedingJobs: [],
  pagination: {
    ...defaultPagination(),
    sortBy: 'name',
  }
})

export default {
  namespaced: true,

  actions: {
    listSeedingJobs({ commit, state }, data) {
      return listSeedingJobs({
        ...data,
        pagination: state.pagination,
      })
        .then(({ result, pagination }) => {
          commit('setSeedingJobs', result);
          commit('setPagination', pagination);
        })
        .catch(error => {
          console.error("Failed to list seeding jobs:", error);
          throw error;
        });
    },
  },

  mutations: {
    setSeedingJobs(state, seedingJobs) {
      state.seedingJobs = seedingJobs;
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    resetPagination(state, newPagination = defaultPagination()) {
      state.pagination = newPagination;
    },
    resetState(state) {
      Object.assign(state, defaultState())
    },
  },
  getters: {
    getAllJobs(state) {
      return state.seedingJobs.map(job => {
        const startTime = new Date(job.startedAt);
        const endTime = new Date(job.endedAt);
        const timeTakenMs = endTime - startTime;
        const timeTakenMinutes = Math.floor(timeTakenMs / 60000);

        return {
          ...job,
          templateType: getTemplateTypes(job.templateType),
          timeTaken: timeTakenMinutes,
          status: getStatusLabels(job.status),
          publishedTemplate: job.publishedTemplate
        };
      });
    }
  },
  state: defaultState()
}
