const SEAT_STATUS = {
  'active': 1,
  'paused': 2,
  'unprotected': 3,
  'archived': 4,
  'unprotecting': 5,
}

const SEAT_STATUS_ACTIONS = {
  'active': 'protect',
  'paused': 'pause',
  'unprotected': 'unprotect',
}

const getEnumLabel = value => {
  return Object.keys(SEAT_STATUS).find(key => SEAT_STATUS[key] === value)
}

const getAlternativesForStatus = status => {
  switch (status) {
    case 1:
      return [2, 3];
    case 2:
      return [1, 3];
    case 3:
      return [1];
    case 4:
      return [3];
    default:
      return [];
  }
};

const getDataSetLabel = label => {
  if (label === 0) {
    return "Contact"
  } else if (label === 1) {
    return "Calendar"
  } else if (label === 2) {
    return "Mail"
  } else if (label === 3) {
    return "Drive"
  } else if (label === 4 || label === 11) {
    return "Conversations"
  } else if (label === 5) {
    return "List"
  } else if (label === 7) {
    return "Tasks"
  }
  return "NA"
}

const getStatus = label => {
  if (label === 'PROGRESS') {
    return "In progress"
  } else if (label === 'COMPLETED') {
    return "Completed"
  } else if (label === 'FAILED') {
    return "Failed"
  } else if (label === 'PENDING') {
    return "Pending"
  }
  else if (label === 'CANCELLED') {
    return "Cancelled"
  }
  return "/"
}

const getRestoreType = restoreType => {
  if (restoreType == 2) {
    return "Skip Existing"
  } else if (restoreType == 3) {
    return "Override older"
  } else if (restoreType == 4) {
    return "Override always"
  }

  return "New Folder"
}

const getTaskStatus = status => {
  if (status === 0) {
    return "Not started"
  } else if (status === 1) {
    return "In progress"
  } else if (status === 2) {
    return "Completed"
  } else if (status === 3) {
    return "Waiting on others"
  } else if (status === 4) {
    return "Deferred"
  }
}

const getJobStatus = label => {
  if (label === 'IN_PROGRESS') {
    return "In progress"
  } else if (label === 'FINISHED') {
    return "Completed"
  } else if (label === 'FAILED') {
    return "Failed"
  } else if (label === 'STARTED') {
    return "Pending"
  } else if (label === 'CANCELED') {
    return 'Canceled'
  }
  return "/"
}

const getJobSourcetype = sourceType => {
  if (sourceType == 0) {
    return 'User'
  } else if (sourceType == 1) {
    return 'Site'
  } else if (sourceType == 2) {
    return 'Team'
  } else if (sourceType == 3) {
    return 'Shared drive'
  } else if (sourceType == 4) {
    return 'Mailbox'
  } else if (sourceType == 5) {
    return 'Team Folders'
  }

  return '/'
}

const getJobEventStatus = status => {
  if (status == -1) {
    return 'Cancelled'
  } else if (status == 0) {
    return 'All'
  } else if (status == 1) {
    return 'Started'
  } else if (status == 2) {
    return 'In progress'
  } else if (status == 3) {
    return 'FINISHED'
  } else if (status == 4) {
    return 'FAILED'
  }

  return '/'
}

const getTypeName = value => {
  if (value === 0) {
    return 'All'
  }
  if (value === 1) {
    return "Backup"
  } else if (value === 2) {
    return "Restore"
  } else if (value === 3) {
    return "Export"
  } else if (value === 4) {
    return "Search"
  } else if (value === 5) {
    return "Seat"
  } else if (value === 6) {
    return "Delete"
  } else if (value === 7) {
    return "S3 Setup"
  } else if (value === 8) {
    return "Broadcast"
  }
  return "/"
}

const getReportingModuleName = (value, provider = 1) => {
  if (value === 1 && provider == 1) {
    return "OneDrive"
  } else if (value === 1 && (provider == 2 || provider == 3 || provider == 4)) {
    return "Drive";
  } else if (value === 2 && provider == 1) {
    return "Mail"
  } else if (value == 2 && provider == 2) {
    return "Gmail"
  } else if (value === 3) {
    return "Calendar"
  } else if (value === 4) {
    return "Contacts"
  } else if (value === 5) {
    return "Outlook"
  } else if (value === 6) {
    return "Team"
  } else if (value === 7) {
    return "SharePoint"
  } else if (value === 8 && provider == 3) {
    return "Team Folders"
  } else if (value === 8) {
    return "Shared Drives"
  } else if (value === 9) {
    return "Conversations"
  } else if (value === 10) {
    return "Tasks"
  }
  return "NA"
}

const getBackupJobModule = (value, provider, sourceType = null) => {
  if (sourceType !== null && sourceType === 5 && provider === 3) {
    return "Team Folders"
  } else if (value === 0) {
    return "Contacts"
  } else if (value === 1) {
    return "Calendar"
  } else if (value === 2) {
    return "Gmail"
  } else if (value === 3 && provider === 1) {
    return "OneDrive"
  } else if (value === 3 && provider === 2) {
    return "Drive"
  } else if (value === 3 && provider === 3) {
    return "Drive"
  } else if (value === 3 && provider === 4) {
    return "Drive"
  } else if (value === 4) {
    return "Outlook"
  } else if (value === 5) {
    return "Team"
  } else if (value === 6) {
    return "SharePoint"
  } else if (value === 7) {
    return "List"
  } else if (value === 8 && provider === 3) {
    return "User"
  } else if (value === 8) {
    return "User"
  } else if (value === 9) {
    return "Shared drives"
  } else if (value === 10) {
    return "Tasks"
  }else if (value === 11) {
    return "Team Folders"
  }
  return "/"
}

const getTemplateTypes = templateType => {
  if (templateType === 1) {
    return "Nodes"
  } else if (templateType === 2) {
    return "Levels"
  }
  return "NA"
}

const getStatusLabels = status => {
  if (status === 0) {
    return "PENDING"
  } else if (status === 1) {
    return "PROGRES"
  } else if (status === 2) {
    return "COMPLETED"
  } else if (status === 3) {
    return "FAILED"
  } else if (status === 4) {
    return "CANCELLED"
  }
  return "NA"
}

const getExportDestinationType = value => {
  if (value === 1) {
    return "AWS S3"
  }
  else if (value === 2) {
    return "Azure Blob"
  }
  else if (value === 3) {
    return "Google Drive"
  }
  else if (value === 4) {
    return "One Drive"
  }
  else if (value === 5) {
    return "Dropbox"
  }
  else if (value === 6) {
    return "Box"
  }

  return "Download"
}

export {
  SEAT_STATUS,
  SEAT_STATUS_ACTIONS,
  getAlternativesForStatus,
  getEnumLabel,
  getDataSetLabel,
  getStatus,
  getTaskStatus,
  getJobStatus,
  getJobSourcetype,
  getJobEventStatus,
  getTypeName,
  getRestoreType,
  getReportingModuleName,
  getBackupJobModule,
  getTemplateTypes,
  getStatusLabels,
  getExportDestinationType
}
