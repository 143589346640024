import {azureConnection} from "@/services/forms/app/recovery/connection/azure";
import {awsConnection} from "@/services/forms/app/recovery/connection/aws";

const setupConnection = (storageDestinationType, regionOption, regionPlaceholder) => (
  storageDestinationType === '1' ? awsConnection(regionOption, regionPlaceholder)
    : storageDestinationType === '2' ? azureConnection
      : {}
)

export {
  setupConnection,
}
